export default function Split({ fillColor }) {
    return (
        <svg id="homeSplitOne" className="split-svg" enable-background="new 0 630 1440 280" fill="none" viewBox="0 630 1440 280" xmlns="http://www.w3.org/2000/svg">
            <path d="M136.106 -271.377C166.053 -276.949 193.587 -295.752 210.53 -321.067C486.511 -733.416 1193.46 -689.373 1505.06 -477.44C1826.66 -258.708 1969.39 178.561 1919.28 360.754C1772.48 894.471 1197.78 895.939 818.27 724.511C718.216 679.316 595.66 699.849 509.92 768.422C-189.965 1328.18 -1320.08 -0.434126 136.106 -271.377Z" fill="#373737"></path>
            <path d="M126.228 -332.018C156.179 -337.573 183.731 -356.383 200.673 -381.697C476.655 -794.046 1183.6 -750.004 1495.21 -538.07C1816.81 -319.338 1959.53 117.93 1909.42 300.123C1762.29 835.035 1185.35 835.312 805.868 662.727C706.935 617.732 585.731 637.488 498.795 702.715C-188.329 1218.25 -1326.45 -62.584 126.228 -332.018Z" fill="rgb(35,40,40)"></path>
            </svg>

    );
  }
//   `
//   M136.106 -271.377
//   C486.511 -733.416 1193.46 -689.373 1505.06 -477.44
//   C1826.66 -258.708 1969.39 178.561 1919.28 360.754
//   C1772.48 894.471 1197.78 895.939 818.27 724.511
//   C718.216 679.316 595.66 699.849 509.92 768.422
//   C-189.965 1328.18 -1320.08 -0.434126 136.106 -271.377Z
//   C166.053 -276.949 193.587 -295.752 210.53 -321.067`